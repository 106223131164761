<template>
  <div class="contents" id="contents">
    <!-- Fullpage -->
    <div id="fullpage">
      <!-- Section 1 -->
      <section class="section s1" id="section1">
        <div class="section-wrap">
          <div class="section-bg" id="scene1"></div>
          <div class="logo-text-wrap">
            <p class="logo-text">DREAMPLUS</p>
          </div>
          <div class="logo-motion-wrap">
            <video id="logo-motion-video" class="logo-motion" muted>
              <source
                :src="getMediaFileURL('logo-motion.mp4')"
                type="video/mp4"
              />
            </video>
          </div>
          <!-- Scroll Text -->
          <div class="scroll">
            <span class="scroll-arrow"></span>
            <span>SCROLL TO EXPLORE</span>
          </div>
          <!-- // Scroll Text -->
        </div>
      </section>
      <!-- // Section 1 -->

      <!-- Section 2 -->
      <section class="section s2" id="section2">
        <div class="section-wrap">
          <div class="section-inner">
            <h2 class="split-title">Connecting Dreams</h2>
            <div class="hand-wrap">
              <div class="hand">
                <img src="@/assets/images/dreamplus/hand-on.png" class="hand-image-on" />
                <img src="@/assets/images/dreamplus/hand-off.png" class="hand-image-off" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- // Section 2 -->

      <!-- Section 3 - Who We Are-->
      <section class="section s3" id="section3">
        <div class="section-wrap">
          <div class="wwa-wrap">
            <div class="text-area">
              <aside class="text-wrap">
                <h2 class="wwa-title">Who We Are</h2>
                <div class="wwa-content-1">
                  <p v-html="$t('content.main.wwa.txt01')">
                  </p>
                </div>
                <div class="wwa-content-2">
                  <p v-html="$t('content.main.wwa.txt02')">
                  </p>
                </div>
                <div class="wwa-content-3">
                  <p v-html="$t('content.main.wwa.txt03')">
                  </p>
                  <!-- Brochure -->
                  <ul class="brochure-wrap">
                    <li>
                      <a href="#" class="brochure down-kor"
                        @click.prevent="downloadWWA(downloadFileKor)"
                      >{{$t('content.main.Common.btn1')}}</a>
                    </li>
                    <li>
                      <a href="#" class="brochure down-eng"
                        @click.prevent="downloadWWA(downloadFileEng)"
                      >{{$t('content.main.Common.btn2')}}</a>
                    </li>
                  </ul>
                  <!-- // Brochure -->
                </div>
              </aside>
            </div>
            <div class="video-area">
              <div class="wwa-video-1">
                <video class="wwa-video" id="wwa-video-1" muted>
                  <source
                    :src="getMediaFileURL('wwa-1.mp4')"
                    type="video/mp4"
                  />
                </video>
              </div>
              <div class="wwa-video-2">
                <video class="wwa-video" id="wwa-video-2" muted>
                  <source
                    :src="getMediaFileURL('wwa-2.mp4')"
                    type="video/mp4"
                  />
                </video>
              </div>
              <div class="wwa-video-3">
                <div class="beach"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- // Section 3-->

      <!-- Section 4 - Brand Philosophy -->
      <section class="section s4" id="section4">
        <div class="section-wrap">
          <ul class="brand-philosophy">
            <li class="brand-mission">
              <div class="layer-image"></div>
              <div class="image-wrap">
                <div class="card-image"></div>
                <dl class="mission-text">
                  <dt>Brand mission</dt>
                  <dd>Connect and Realize Dreams</dd>
                </dl>
                <div class="layer-over">
                  <p v-html="$t('content.main.wwa.txt04')">
                  </p>
                </div>
              </div>
            </li>
            <li class="brand-slogan">
              <div class="layer-image"></div>
              <div class="image-wrap">
                <div class="card-image"></div>
                <dl class="slogan-text">
                  <dt>Brand slogan</dt>
                  <dd>Connecting Dreams</dd>
                </dl>
                <div class="layer-over">
                  <p v-html="$t('content.main.wwa.txt05')">
                  </p>
                </div>
              </div>
            </li>
            <li class="brand-essence">
              <div class="layer-image"></div>
              <div class="image-wrap">
                <div class="card-image"></div>
                <dl class="essence-text">
                  <dt>Brand essence</dt>
                  <dd>
                    Facilitator for Open Innovation
                    <br />and Realizing Dreams
                  </dd>
                </dl>
                <div class="layer-over">
                  <p v-html="$t('content.main.wwa.txt06')">
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <!-- // Section 4-->

      <!-- Section 5 - Brand Identity -->
      <section class="section s5" id="section5">
        <div class="section-wrap">
          <div class="brand-identity">
            <div class="cloud-area">
              <div class="cloud-layer"></div>
              <div class="cloud-container">
                <div class="cloud-top-wrap">
                  <div class="cloud-top">
                    <span class="cloud-top-bg">
                      <img src="@/assets/images/dreamplus/mask-2.png" class="cloud-top-frame" />
                    </span>
                  </div>
                  <div class="brand-inner">
                    <h2 class="brand-identity-title">
                      CONNECTING DREAMS,
                      <br />DREAMPLUS
                    </h2>
                    <div class="brand-identity-description"
                      v-html="$t('content.main.wwa.txt07')">
                    </div>
                  </div>
                </div>
                <div class="cloud-bottom">
                  <span class="cloud-bottom-bg">
                    <img src="@/assets/images/dreamplus/mask-1.png" class="cloud-bottom-frame" />
                  </span>
                </div>
                <!-- Reddot Award -->
                <div class="reddot-wrap">
                  <div class="reddot-award">
                    <span class="blind">reddot winner 2020 brand design</span>
                  </div>
                </div>
                <!-- // Reddot Award -->
              </div>
            </div>
          </div>
          <div class="line-vertical"></div>
          <ul class="symbol-wrap">
            <li>
              <div class="symbol">
                <div class="symbol-image-wrap">
                  <img
                    src="@/assets/images/dreamplus/symbol-img-1.png"
                    class="symbol-image"
                    alt="심볼 이미지"
                  />
                </div>
                <dl class="symbol-desc-wrap">
                  <dt>Signature</dt>
                  <dd v-html="$t('content.main.wwa.txt08')">
                  </dd>
                </dl>
              </div>
            </li>
            <hr class="line" />
            <li>
              <div class="symbol">
                <div class="symbol-image-wrap">
                  <video class="symbol-motion" width="210" height="248" muted loop data-autoplay>
                    <source
                      :src="getMediaFileURL('logo-full-motion.mp4')"
                      type="video/mp4"
                    />
                  </video>
                </div>
                <dl class="motion-desc-wrap">
                  <dt>Motion Logo</dt>
                  <dd v-html="$t('content.main.wwa.txt09')">
                  </dd>
                </dl>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <!-- //  Section 5 -->

      <!-- Section 6 - Brand Film -->
      <section class="section s6" id="section6">
        <div class="section-wrap">
          <div class="section-bg film-bg"></div>
          <div class="section-inner">
            <h2 class="film-title">DREAMPLUS BRAND FILM</h2>
            <div class="video-wrap brand-film">
              <div class="video-inner">
        <iframe width="100%" height="100%" id="brandFilm"
          data-src="https://www.youtube.com/embed/KBvs-dyZpHo?rel=0&enablejsapi=1&controls=0&mute=0&showinfo=0&loop=1"
          frameborder="0"
          allow="autoplay"
          muted
          allowfullscreen
          data-autoplay
          title="youtube 영상">
        </iframe>
              <!-- <video id="brand-film" class="brand-film" >
                <source
                  :src="getMediaFileURL('dreamplus-brand-film.mp4')"
                  type="video/mp4"
                />
              </video> -->
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- // Section 6-->

      <!-- Section Footer -->
      <section class="section fp-auto-height s7" id="section7">
        <!-- FOOTER -->
        <GlobalFooter></GlobalFooter>
        <!-- // FOOTER -->
      </section>
      <!-- // Section Footer -->
    </div>
    <!-- // Fullpage -->
  </div>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import { FilePaths } from '@/common/portalApi';
import GlobalFooter from '@/views/layout/GlobalFooter.vue';
import { mixinMainMostion } from '@/common/MainMostion';
import '@/assets/css/main.css';
import CoronaEvent from '@/components/popup/PopupCoronaEvent.vue';
import PopupApply from '@/components/popup/PopupApplyProgram.vue';
// import PopupGangnam from '@/components/popup/PopupGangnam.vue';

export default {
  name: 'WhoWeAre',
  mixins: [mixinHelperUtils, mixinMainMostion],
  components: {
    GlobalFooter,
  },
  data() {
    return {
      mainFullpage: undefined,
      orginClickScrollTopHandler: undefined,
      downloadFileEng: '%5BDREAMPLUS%5DBrochure_Eng.pdf',
      downloadFileKor: '%5BDREAMPLUS%5DBrochure_Kor.pdf',
    };
  },
  watch: {
    $route(newValue, oldValue) {
      if (newValue.fullPath !== oldValue.fullPath) {
        const { hash } = window.location;
        const idxActiveSection = window.fullpage_api.getActiveSection().index;
        window.fullpage_api.reBuild();
        if (idxActiveSection === 1) {
          document.querySelector('header').classList.add('intro');
        } else {
          document.querySelector('header').classList.remove('intro');
        }
        if (hash === '#section2') {
          document.querySelector('#section1').classList.add('hide');
        } else {
          document.querySelector('#section1').classList.remove('hide');
        }

        window.fullpage_api.silentMoveTo(1);
        setTimeout(() => {
          window.scrollTo(window, 1);
        }, 100);
      }
    },
  },
  created() {
    /**
     * App.vue(compentent)의 변환
     * fullpage 라이브러리의 오류로 인해
     * 커버페이지가 없을경우(해쉬값으로 진입시)
     * - window.scrollY = 0 경우 fullpage의 이동관련한 method들이 멈추는 형상으로 인해
     */
    this.orginClickScrollTopHandler = this.$parent.$parent.$children[0].onScrollToTopClick;
    this.$parent.$parent.$children[0].onScrollToTopClick = () => {
      this.scrollTo(1, 1, 0);
    };

    /**
     * GNB 스크롤 비활성화(독립적 이벤트 사용)
     */
    window.removeEventListener('scroll', this.$parent.$parent.$children[0].onWindowScroll);
  },
  methods: {
    /* mixinMainMostion */
    downloadWWA(fileName) {
      const path = this.getFileURL(FilePaths.ETC, fileName);
      this.downloadFile(path, fileName);
    },

    initGnbClass() {
      document.querySelector('header').classList.remove('intro');
      document.querySelector('header').classList.remove('sticky');
      document.querySelector('.gnb-wrap').classList.remove('hide');
      document.querySelector('.sub-menu').classList.remove('sub-active');
      document.querySelector('.nav-bar').classList.remove('on');
    },
    /**
     * 코로나 팝업
     */
    showCoronaEvent(opt) {
      this.showPopup(CoronaEvent, 'corona-event', opt);
    },
    /**
     * 공모전 팝업
     */
    showPopupApply(opt) {
      this.showPopup(PopupApply, 'popup-apply', opt);
    },
    /**
     * 방문예약 팝업
     */
    // showPopupGangnam(opt) {
    //   this.showPopup(PopupGangnam, 'pop-gangnam', opt);
    // },
  },

  mounted() {
    const visibleCorona = this.$moment().isAfter('20201005 0000') && this.$moment().isBefore('20200130 2359')
    && this.$cookies.get('pop-corona') !== 'true';
    if (visibleCorona) {
      setTimeout(() => {
        this.showCoronaEvent(visibleCorona);
      }, 0);
    }
    const visiblePopupApply = this.$moment().isAfter('20201201 0000') && this.$moment().isBefore('2020131 2359')
    && this.$cookies.get('pop-apply') !== 'true';
    if (visiblePopupApply) {
      setTimeout(() => {
        this.showPopupApply(visiblePopupApply);
      }, 0);
    }
    // const visiblePopupGangnam = this.$moment().isAfter('20201201 0000') && this.$moment().isBefore('20221231 2359')
    // && this.$cookies.get('pop-gangnam') !== 'true';
    // if (visiblePopupGangnam) {
    //   setTimeout(() => {
    //     this.showPopupGangnam(visiblePopupGangnam);
    //   }, 0);
    // }
  },

  destroyed() {
    /**
     * GNB 스크롤 비활성화
     */
    window.addEventListener('scroll', this.$parent.$parent.$children[0].onWindowScroll);

    /**
     * fullpage: destroy
     */
    this.mainFullpage.destroy('all');

    /**
     * 독립적으로 돌아가는 부분을 초기화 해줌
     */
    this.initGnbClass();

    /**
     * App.vue(compentent)의 orgin 값으로 원복시킴
     */
    this.$parent.$parent.$children[0].onScrollToTopClick = this.orginClickScrollTopHandler;
  },
};
</script>

<style>
body[class*='fp-viewing'] .header .gnb > li:nth-child(1) .sub-menu > li:nth-child(1) > a {color:#111}
</style>
